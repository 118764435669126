import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";

import impressumCSS from './css/impressum.css';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export const ImpressumView = () =>{
    const { t } = useTranslation();

    return(
        <Container className="wrapper">
            <Row>
                <Col sm={10} md={10} className="m-auto">
             
            <h1 className="impressum-main-heading mt-5">{t("impressumHeading")}</h1>
            <h3>{t("impressumAccordingTo")} §5 DDG</h3><br/>
            <p>Yevhenii Airapetian</p>
            <p>c/o IP-Management #42686</p>
            <p>Ludwig-Erhard-Str. 18</p>
            <p>20459 Hamburg</p><br/>
            <h4>
                <p><strong>{t("impressumContact")}</strong></p>
            </h4>
            <p>{t("impressumPhone")} +4915237795765
            </p>
            <p>{t("impressumEmail")} <Link className="impressum-links" to="contact@yevheniiairapetian.com">contact@yevheniiairapetian.com</Link></p>
            
            <span>{t("impressumSource")} </span> <span className="impressum-links" >Impressum-Privatschutz</span> 
               
        
                </Col>
            </Row>
        </Container>


        
          
        )
        }