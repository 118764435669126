import { useTranslation } from "react-i18next";
import { Navbar, Container, Row, Col, Nav, Image, Modal, Button } from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BsMoon, BsSun } from "react-icons/bs";
import Cookies from "js-cookie";
import Lebenslauf from './img/Yevhenii-Airapetian-Lebenslauf-DE.pdf';
import CV from './img/Yevhenii-Airapetian-CV-EN.pdf';
import Resume from './img/Yevhenii-Airapetian-CV-UA.pdf';
import '../../../src/App.css';
import { ScrollToAnchor } from "../scroll-to-anchor/scroll-to-anchor";
import { useState, useEffect } from "react";
import useDarkMode from "./../../hooks/useDarkMode";
import navigationCSS from './css/navigation.css';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { faGlobe, faBell, faVolumeXmark, faVolumeHigh, faDesktop, faPersonRunning, faCircleInfo, faBolt, faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSound from 'use-sound';
import Click from './src/click.wav';
import { useSoundSettings } from './../soundContext/soundContext';

export const NavigationBar = () => {




	const ClickButton = () => {

		const [play] = useSound(Click);
		const { soundsEnabled } = useSoundSettings();
		return <Button className="info-sound-click-button" onClick={() => { if (soundsEnabled) { play() }; setVisible(!visible) }}>
		</Button>;
	}

	const SoundToggleButton = () => {
		const [play] = useSound(Click);
		const { soundsEnabled, toggleSounds } = useSoundSettings(); return (<Button className="mute-button fixed-ltr" onClick={toggleSounds}> {soundsEnabled ? <FontAwesomeIcon className="sound-tgl-icon" onClick={() => { handleShowSoundDisabledModal() }} title={t("muteAppTitle")} icon={faVolumeXmark} style={{ color: "#fff", }} size="lg" /> : <FontAwesomeIcon className="sound-tgl-icon" onClick={() => { if (soundsEnabled) { play() }; handleShowSoundEnabledModal(); }} title={t("unmuteAppTitle")} icon={faVolumeHigh} style={{ color: "#fff", }} size="lg" />} </Button>);
	}

	const Themes = () => {
		const { soundsEnabled } = useSoundSettings();

		const [play] = useSound(Click);
		return <div title="🌓">

			<NavDropdown onClick={() => { if (soundsEnabled) { play() } }} className="cases-nav-container theme-options-nav-container" title="🌓" id="collapsible-nav-dropdown">



				<NavDropdown.Item className="themes-links">
					<ClickThemeDark />
				</NavDropdown.Item>
				<NavDropdown.Item className="themes-links">
					<ClickThemeLight />
				</NavDropdown.Item>
				<NavDropdown.Item className="themes-links">
					<ClickThemeOld />
				</NavDropdown.Item>
				<NavDropdown.Item className="themes-links">
					<ClickThemeReducedMotion />
				</NavDropdown.Item>
				<NavDropdown.Item className="themes-links">
					<ClickThemeSystem />
				</NavDropdown.Item>

			</NavDropdown>
		</div>
	};

	const ClickCases = () => {
		const [play] = useSound(Click);
		const { soundsEnabled } = useSoundSettings();

		return (
			<NavDropdown onClick={() => { if (soundsEnabled) { play() } }} className="cases-nav-container" title={t("menu.casesMenu")} id="collapsible-nav-dropdown">



				<NavDropdown.Item target="_self" alt="">
					<Link onClick={() => { if (soundsEnabled) { play() }; setExpanded(false) }} to="./../r3play-case" className="nav-cases" target="_self" >{t("menu.r3playMenu")}</Link>
				</NavDropdown.Item>
				<NavDropdown.Item target="_self" alt="">
					<Link onClick={() => { if (soundsEnabled) { play() }; setExpanded(false) }} to="./../myFlix-case" className="nav-cases" target="_self" >{t("menu.myFlixMenu")}</Link>
				</NavDropdown.Item>
				<NavDropdown.Item target="_self" alt="">
					<Link onClick={() => { if (soundsEnabled) { play() }; setExpanded(false) }} to="./../meet-case" className="nav-cases" target="_self" >{t("menu.meetMenu")}</Link>
				</NavDropdown.Item>
				<NavDropdown.Item target="_self" alt="">
					<Link onClick={() => { if (soundsEnabled) { play() }; setExpanded(false) }} to="./../owrite-case" className="nav-cases" target="_self" >{t("menu.owriteMenu")}</Link>
				</NavDropdown.Item>
				<NavDropdown.Item target="_self" alt="">
					<Link onClick={() => { if (soundsEnabled) { play() }; setExpanded(false) }} to="./../pokemon-case" className="nav-cases" target="_self" >{t("menu.pokemonMenu")}</Link>
				</NavDropdown.Item>
				<NavDropdown.Item target="_self" alt="">
					<Link onClick={() => { if (soundsEnabled) { play() }; setExpanded(false) }} to="./../portfolio-case" className="nav-cases" target="_self" >{t("menu.portfolioMenu")}</Link>
				</NavDropdown.Item>

			</NavDropdown>
		)
	}


	const ClickNavResumes = () => {
		const [play] = useSound(Click);
		const { soundsEnabled } = useSoundSettings();

		return (
			<NavDropdown onClick={() => { showMenuDownloadLinks(); if (soundsEnabled) { play() } }} title={t("menu.resumeMenu")} id="collapsible-nav-dropdown">



				<NavDropdown.Item className="text-center" title={t("aboutCvDeTitle")} href={Lebenslauf} download="Yevhenii-Airapetian-Lebenslauf-DE" target="_blank" rel="noopener noreferrer">
					<img className="flags mt-3 me-3" alt="An icon showing the German flag" src="https://hatscripts.github.io/circle-flags/flags/de.svg" width="28" />
					<Link onClick={() => { if (soundsEnabled) { play() }; setExpanded(false) }} to={Lebenslauf} title="Download the resume version in German" className="download-de" target="_blank" ></Link>
				</NavDropdown.Item>
				<NavDropdown.Item className="text-center" title={t("aboutCvEnTitle")} href={CV} download="Yevhenii-Airapetian-CV-EN" target="_blank" rel="noopener noreferrer">
					<img className="flags mt-3 me-3" alt="An icon showing the USA flag" src="https://hatscripts.github.io/circle-flags/flags/us.svg" width="28" />
					<Link onClick={() => { if (soundsEnabled) { play() }; setExpanded(false) }} to={CV} title="Download the resume version in English" className="download-en" target="_blank" ></Link>
				</NavDropdown.Item>
				<NavDropdown.Item className="text-center" title={t("aboutCvUkTitle")} href={Resume} download="Yevhenii-Airapetian-CV-UA" target="_blank" rel="noopener noreferrer">
					<img className="flags mt-3 me-3" alt="An icon showing Ukrainian flag" src="https://hatscripts.github.io/circle-flags/flags/ua.svg" width="28" />
					<Link onClick={() => { if (soundsEnabled) { play() }; setExpanded(false) }} to={Resume} title="Download the resume zip folder" className="download-uk" target="_blank" ></Link>
				</NavDropdown.Item>

			</NavDropdown>
		)
	}
	const ClickTerms = () => {
		const [play] = useSound(Click);
		const { soundsEnabled } = useSoundSettings();

		return (
			<NavDropdown onClick={() => { if (soundsEnabled) { play() } }} className="cases-nav-container" title={t("menu.policiesMenu")} id="collapsible-nav-dropdown">



				<NavDropdown.Item href="https://www.freeprivacypolicy.com/live/13aaa5dd-c830-46ce-8e8a-010efe1a66f1" target="_blank" alt="">
					<Link onClick={() => { if (soundsEnabled) { play() }; setExpanded(false) }} to={"https://www.freeprivacypolicy.com/live/13aaa5dd-c830-46ce-8e8a-010efe1a66f1"} className="nav-cases" target="_blank" >{t("menu.cookies")}</Link>
				</NavDropdown.Item>
				<NavDropdown.Item href="https://www.freeprivacypolicy.com/live/c361241a-8f0b-4be3-8d44-56ba30ba476a" target="_blank" alt="">
					<Link onClick={() => { if (soundsEnabled) { play() }; setExpanded(false) }} to={"https://www.freeprivacypolicy.com/live/c361241a-8f0b-4be3-8d44-56ba30ba476a"} className="nav-cases" target="_blank" >{t("menu.privacy")}</Link>
				</NavDropdown.Item>


			</NavDropdown>
		)
	}

	const ClickThemeDark = () => {
		const [play] = useSound(Click);
		const { soundsEnabled } = useSoundSettings();

		return <button
			title={t("themeSwitcherLightHint")}

			className="toggle_btn w-100" onClick={() => { handleThemeChange("light"); if (soundsEnabled) { play() }; handleShowLightModal(); setExpanded(false) }}>

			<FontAwesomeIcon className="sun"
				title={t("themeSwitcherLightHint")}
				icon={faSun} style={{ color: "gold" }} />
		</button>
	};


	const ClickThemeLight = () => {
		const [play] = useSound(Click);
		const { soundsEnabled } = useSoundSettings();

		return <button title={t("themeSwitcherDarkHint")} className="toggle_btn w-100" onClick={() => { handleThemeChange("dark"); if (soundsEnabled) { play() }; handleShowDarkModal(); setExpanded(false) }}>
			<FontAwesomeIcon className="moon"
				title={t("themeSwitcherDarkHint")}
				icon={faMoon} style={{ color: "#000000" }} />
		</button>
	};

	const ClickThemeOld = () => {
		const [play] = useSound(Click);
		const { soundsEnabled } = useSoundSettings();

		return <button
			title={t("themeSwitcherGlitchHint")}

			className="toggle_btn w-100" onClick={() => { if (soundsEnabled) { play() }; handleThemeChange("old"); handleShowOldModal(); setExpanded(false) }}>
			<FontAwesomeIcon className="moon bolt"
				title={t("themeSwitcherGlitchHint")}
				icon={faBolt} style={{ color: "#74CoFC" }} />
		</button>
	};

	const ClickThemeReducedMotion = () => {
		const [play] = useSound(Click);
		const { soundsEnabled } = useSoundSettings();

		return <button
			title={t("themeSwitcherMotionHint")}

			className="toggle_btn w-100" onClick={() => { if (soundsEnabled) { play() }; handleThemeChange("reduced-motion"); handleShowMotionModal(); setExpanded(false) }}>
			<FontAwesomeIcon className="moon reduced-motion"
				title={t("themeSwitcherMotionHint")}
				icon={faPersonRunning} style={{ color: "#74CoFC" }} />
		</button>
	};

	const ClickThemeSystem = () => {
		const [play] = useSound(Click);
		const { soundsEnabled } = useSoundSettings();

		return <button
			title={t("themeSwitcherSystemHint")}

			className="toggle_btn w-100" onClick={() => { if (soundsEnabled) { play() }; handleThemeChange("system"); handleShowSystemModal(); setExpanded(false) }}>
			<FontAwesomeIcon className="moon system"
				title={t("themeSwitcherSystemHint")}
				icon={faDesktop} style={{ color: "#74CoFC" }} />
		</button>
	};

	const ClickLanguage = () => {
		const [play] = useSound(Click);
		const { soundsEnabled } = useSoundSettings();

		return <select title={t("langHint")} onChange={handleChangeLocale} onClick={() => { if (soundsEnabled) { play() } }} value={language}>

			{languages.map(({ name, code }) => (
				<option style={{
					height: "89px", width: "56px"

				}} key={code} value={code}>
					<span onClick={() => { if (soundsEnabled) { play() } }} style={{ display: "inline-block", fontSize: "24px", color: "#ffffff" }}>🌏︎ </span>
					<span className="lang-option">{name}</span>
				</option>
			))}
		</select>
	};



	const ClickBell = () => {
		const { soundsEnabled } = useSoundSettings();

		const [play] = useSound(Click);
		return <div>
			{currentTableData.map(item => (
				<div key={item.id}>

					<button title={t("notificationHint")} className="notification-button pl-4" onClick={() => { if (soundsEnabled) { play(); } setVisible(!visible) }}>
						{visible ? <FontAwesomeIcon className="bell-calm" icon={faBell} size="lg" style={{ color: "#529fcc", }} /> : <FontAwesomeIcon className="bell-active" icon={faBell} shake size="lg" style={{ color: "#ffffff", "--fa-animation-iteration-count": "1" }} />}
					</button>

					{visible && <div className="notification"><div><div title={t("notificationCloseTitle")} className="close-notification-container"><ClickButton  ><span className="text-primary"></span></ClickButton></div></div><div className="new-info">{t("whatsNewInfo")}</div>

						<Link className="more-info-dialog-links" onClick={() => { setVisible(!visible) }} to={"./../portfolio-case"}  >
							<span className="notification-more-link">{t("whatsNewLink")}</span></Link>
						<div className="new-info">{t("whatsNewInfo2")}</div>
						<Link className="more-info-dialog-links" onClick={() => { setVisible(!visible) }} to={"./../blog"}>
							<span className="notification-more-link">{t("whatsNewLink2")}</span></Link>

					</div>

					}
				</div>
			))}
		</div>
	};
	const [visible, setVisible] = useState(false);
	const currentTableData = [
		{ id: 1, title: 'What\'s new: the app moved to React, is localized, and has a theme toggler. Read more: ' },
		// { id: 2, title: 'BBB' },
		// { id: 3, title: 'CCC' }
	];
	// const [showNewVisitorModal, setShowNewVisitorModal] = useState(true);
	const [showDarkModal, setShowDarkModal] = useState(false);
	const [showSoundEnabledModal, setShowSoundEnabledModal] = useState(false);
	const [showSoundDisabledModal, setShowSoundDisabledModal] = useState(false);
	const [showSystemModal, setShowSystemModal] = useState(false);
	const [showMotionModal, setShowMotionModal] = useState(false);
	const [showOldModal, setShowOldModal] = useState(false);
	const [showLightModal, setShowLightModal] = useState(false);
	const handleShowLightModal = () => setShowLightModal(true);
	const handleShowSoundEnabledModal = () => setShowSoundEnabledModal(true);
	const handleShowSoundDisabledModal = () => setShowSoundDisabledModal(true);
	const handleShowDarkModal = () => setShowDarkModal(true);
	const handleShowSystemModal = () => setShowSystemModal(true);
	const handleShowMotionModal = () => setShowMotionModal(true);
	const handleShowOldModal = () => setShowOldModal(true);
	//   const handleCloseNewVisitorModal = () => setShowNewVisitorModal(false);
	const handleCloseLightModal = () => setShowLightModal(false);
	const handleCloseSoundEnabledModal = () => setShowSoundEnabledModal(false);
	const handleCloseSoundDisabledModal = () => setShowSoundDisabledModal(false);
	const handleCloseDarkModal = () => setShowDarkModal(false);
	const handleCloseSystemModal = () => setShowSystemModal(false);
	const handleCloseMotionModal = () => setShowMotionModal(false);
	const handleCloseOldModal = () => setShowOldModal(false);

	const [theme, setTheme] = useDarkMode();
	const handleThemeChange = (newTheme) => { setTheme(newTheme); };
	const { t, i18n } = useTranslation();
	const [expanded, setExpanded] = useState(false);



	function showMenuDownloadLinks() {
		let linkDE = $('.download-menu-de');
		let linkEN = $('.download-menu-en');
		let linkUK = $('.download-menu-uk');
		let caret = $('.resume-caret');
		linkDE.fadeToggle();
		linkEN.fadeToggle();
		linkUK.fadeToggle();
		caret.fadeToggle();


	}


	function closeMenuDownloadLinks() {
		let linkDE = $('.download-menu-de');
		let linkEN = $('.download-menu-en');
		let linkUK = $('.download-menu-uk');
		let caret = $('.resume-caret');
		linkDE.fadeOut();
		linkEN.fadeOut();
		linkUK.fadeOut();
		caret.fadeOut();


	}
	const languages = [
		{ name: "EN", code: "en" },
		{ name: "DE", code: "de" },
		{ name: "ES", code: "es" },
		{ name: "PT", code: "pt" },
		{ name: "IT", code: "it" },
		{ name: "FR", code: "fr" },
		{ name: "UK", code: "uk" },
		{ name: "RU", code: "ru" },
		{ name: "MK", code: "mk" },
		{ name: "PL", code: "pl" },
		{ name: "日本語", code: "ja" },
		{ name: "中文", code: "zh" },
		{ name: "한국어", code: "ko_KR" },
		{ name: "TR", code: "tr" },
		// { name: "العربية", code: "ar", dir: "rtl" },

	];
	const currentLocale = Cookies.get("i18next") || "en";
	const currentLangObj = languages.find((lang) => lang.code === currentLocale);

	const [language, setLanguage] = useState(currentLocale);

	const handleChangeLocale = (e) => {
		const lang = e.target.value;
		setLanguage(lang);
		i18n.changeLanguage(lang);
	};



	useEffect(() => {


		document.body.dir = 'ltr';
		document.title = t("app_title");
	}, [currentLangObj, t]);


	return (
		<>
			<Navbar expanded={expanded} onClick={() => { closeMenuDownloadLinks() }} className="page-header" expand="xl" id="navigation">
				<Container className="navigation">
					<ScrollToAnchor />
					<Navbar.Brand className="p-2 brand" as={Link} to="/" expand="lg">
						<Image onClick={() => setExpanded(false)} className="img-responsive logo"
							alt={t("logoAlt")}
							src={"../../logo.svg"} />



					</Navbar.Brand>
					<Navbar.Toggle id="tgl" onClick={() => setExpanded(!expanded)} />
					<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
						<Nav>

							<Nav.Link onClick={() => setExpanded(false)} className="text-light pe-4" as={Link} to='/'>
								{t("menu.aboutMenu")}
							</Nav.Link>
							<Nav.Link onClick={() => setExpanded(false)} className="text-light pe-4" as={Link} to='/projects'>
								{t("menu.projectsMenu")}

							</Nav.Link>


							<ClickCases />


							<Nav.Link onClick={() => setExpanded(false)} className="text-light pe-4" as={Link} to='/testimonials'>
								{t("menu.testimonialsMenu")}
							</Nav.Link>
							<Nav.Link onClick={() => setExpanded(false)} className="text-light pe-4" as={Link} to='/contact'>
								{t("menu.contactMenu")}
							</Nav.Link>
							<Nav.Link onClick={() => setExpanded(false)} className="text-light pe-4" as={Link} to='/blog'>
								{t("menu.blogMenu")}
							</Nav.Link>


							<ClickNavResumes />

							<ClickTerms />


							<div className="switcher pl-3">

								<ClickLanguage />
							</div>

							<Themes />


							<Nav.Link className="bell-btn text-light pe-4">
								<ClickBell />
							</Nav.Link>

							<SoundToggleButton />


						</Nav>

					</Navbar.Collapse>
				</Container>
			</Navbar>
			<Modal

				className="favorite-modal" show={showDarkModal} onHide={handleCloseDarkModal}>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body className="text-dark bg-white dark-modal-body"><FontAwesomeIcon className="pr-2" icon={faCircleInfo} fade style={{ color: "#529fcc", }} size="lg" />{t("darkModalMessage")}</Modal.Body>

				<Button title={t("modalHint")} className="got-it-button text-dark bg-white dark-modal-button" onClick={handleCloseDarkModal}>{t("modalConfirm")}</Button>

			</Modal>

			<Modal

				className="favorite-modal" show={showSoundEnabledModal} onHide={handleCloseSoundEnabledModal}>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body className="text-dark bg-white dark-modal-body"><FontAwesomeIcon className="pr-2" icon={faCircleInfo} fade style={{ color: "#529fcc", }} size="lg" />{t("modalUnmuted")}</Modal.Body>

				<Button title={t("modalHint")} className="got-it-button text-dark bg-white dark-modal-button" onClick={handleCloseSoundEnabledModal}>{t("modalConfirm")}</Button>

			</Modal>

			<Modal

				className="favorite-modal" show={showSoundDisabledModal} onHide={handleCloseSoundDisabledModal}>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body className="text-dark bg-white dark-modal-body"><FontAwesomeIcon className="pr-2" icon={faCircleInfo} fade style={{ color: "#529fcc", }} size="lg" />{t("modalMuted")}</Modal.Body>

				<Button title={t("modalHint")} className="got-it-button text-dark bg-white dark-modal-button" onClick={handleCloseSoundDisabledModal}>{t("modalConfirm")}</Button>

			</Modal>

			<Modal

				className="favorite-modal" show={showLightModal} onHide={handleCloseLightModal}>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body className="text-dark bg-white"><FontAwesomeIcon className="pr-2" icon={faCircleInfo} fade style={{ color: "#529fcc", }} size="lg" />{t("lightModalMessage")}

				</Modal.Body>

				<Button title={t("modalHint")} className="got-it-button light-modal-button" onClick={handleCloseLightModal}>{t("modalConfirm")}</Button>
			</Modal>

			<Modal

				className="favorite-modal" show={showOldModal} onHide={handleCloseOldModal}>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body className="text-dark bg-white"><FontAwesomeIcon className="pr-2" icon={faCircleInfo} fade style={{ color: "#529fcc", }} size="lg" />{t("glitchModalMessage")}

				</Modal.Body>

				<Button title={t("modalHint")} className="got-it-button light-modal-button" onClick={handleCloseOldModal}>{t("modalConfirm")}</Button>
			</Modal>

			<Modal

				className="favorite-modal system-modal" show={showSystemModal} onHide={handleCloseSystemModal}>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body className="system-modal-favorite"><FontAwesomeIcon className="pr-2" icon={faCircleInfo} fade style={{ color: "#529fcc", }} size="lg" />{t("systemModalMessage")}

				</Modal.Body>

				<Button title={t("modalHint")} className="got-it-button light-modal-button" onClick={handleCloseSystemModal}>{t("modalConfirm")}</Button>
			</Modal>

			<Modal

				className="favorite-modal motion-modal-favorite" show={showMotionModal} onHide={handleCloseMotionModal}>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body className="motion-modal-body text-dark pr-5"><FontAwesomeIcon className="pr-2" icon={faCircleInfo} fade style={{ color: "#529fcc", }} size="lg" />{t("motionModalMessage")}

				</Modal.Body>

				<Button title={t("modalHint")} className="got-it-button light-modal-button" onClick={handleCloseMotionModal}>{t("modalConfirm")}</Button>
			</Modal>

		</>
	);
};
