import { Navbar, Button, Container, Row, Col, Nav, Image } from "react-bootstrap";
import $ from 'jquery';
import profilePicture from './img/yevhenii_airapetian_profile_picture.webp';
import Lebenslauf from './img/Yevhenii-Airapetian-Lebenslauf-DE.pdf';
import CV from './img/Yevhenii-Airapetian-CV-EN.pdf';
import Resume from './img/Yevhenii-Airapetian-CV-UA.pdf';
import { useTranslation } from 'react-i18next';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

import css from './css/about.css';

export const AboutView = () => {
	function showDownloadLinks(){
        
        let resumes = $('.resumes');
        
          resumes.fadeToggle();
        
      }

      function closeDownloadLinks(){
        let resumes = $('.resumes');
        let content = $('.close-notification');
        content.on("click", function(){$(".notification").fadeOut()});
        
          // notification.fadeOut();
          resumes.fadeOut();
        
      }
      const { t } = useTranslation();
	return (

		<Container fluid className="aboutAnimation about-page wrapper close-notification aboutContainer pt-4">
			<Row >
				<Col className="m-auto" xs={12} sm={9} md={6} lg={4} >
                <Image alt={t("profileImageAlt")} onClick={()=>{closeDownloadLinks()}} className="img-responsive profile-picture pt-3 pb-3 pe-2" fluid roundedCircle src={profilePicture} />
                </Col>
                <Col className="h-100 about-block" xs={12} md={6} lg={8}>
                <h1 className="r3play-headings">{t("welcome text")}</h1>
            <p onClick={()=>{closeDownloadLinks()}} className="introduction">{t("introduction")}</p>
            <p onClick={()=>{closeDownloadLinks()}} className="prevExperience">{t("experience")}</p>
            <p onClick={()=>{closeDownloadLinks()}} className="myFocus">{t("first focus")}</p>
            <p onClick={()=>{closeDownloadLinks()}} className="mySecondFocus">{t("second focus")}</p>
            <p onClick={()=>{closeDownloadLinks()}} className="availability">{t("availability")}</p>
            <p onClick={()=>{closeDownloadLinks()}} className="availability hobbies mb-4">{t("hobbies")}</p>
            
            <Button variant="primary" className="check-out fade-in-up-on-scroll ps-3 pe-1" href="https://github.com/yevheniiairapetian" target="_blank" rel="noopener noreferrer"><span title={t("checkOutCodeTitle")} className="code-span">{t("code")}</span>
            <FontAwesomeIcon className="pl-2 pr-2 about-git" icon={faGithub} />
            </Button>
            <Button 
            variant="dark" 
            onClick={()=>{showDownloadLinks()}} 
            className="resume fade-in-up-on-scroll ps-3 pe-1 ms-2">
                <span title={t("toggleResumeTitle")} className="about-buttons-big-screen resumeBig">{t("resume")}</span>
                <FontAwesomeIcon className="pl-2 pr-2 about-download" size="xl" icon={faFileArrowDown} />
                </Button><br/>
                <div className="resumes">
                  
                <a title={t("aboutCvDeTitle")} className="download-de" href={Lebenslauf} download="Yevhenii-Airapetian-Lebenslauf-DE" target="_blank" rel="noopener noreferrer">
                <img className="flags mt-3 me-3" alt={t("aboutCvDeAlt")} src="https://hatscripts.github.io/circle-flags/flags/de.svg" width="29"/>
                 </a> 
            <a title={t("aboutCvEnTitle")} className="download-en" href={CV} download="Yevhenii-Airapetian-CV-EN" target="_blank" rel="noopener noreferrer">
            <img className="flags mt-3 me-3" alt={t("aboutCvEnAlt")} src="https://hatscripts.github.io/circle-flags/flags/us.svg" width="29"/>
              </a> 
            <a title={t("aboutCvUkTitle")} className="download-uk" href={Resume} download="Yevhenii-Airapetian-CV-UA" target="_blank" rel="noopener noreferrer">
            <img className="flags mt-3 me-3" alt={t("aboutCvUkAlt")} src="https://hatscripts.github.io/circle-flags/flags/ua.svg" width="29"/>
              </a> 
                
            </div>    
                </Col>
            </Row>
            
            
                
					
				
				
				
					
					
								
								
								 
                                
                               
							
					
					
				
			</Container>
		
	);

};