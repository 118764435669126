import { Button, Form, Container, Row, Col, Image } from "react-bootstrap";
import contactCSS from './css/contact.css';
import $ from 'jquery';
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import blogImg from './img/blog.jpg';
import logo from './img/logo.svg';

export const BlogView = () => {
  const { t } = useTranslation();
 
  return (
    <Container className="close-notification">
      <Row>
        <Col
          className="wrapper contact-main_container"
        >
          <div>
            <h1 className="about-heading">{t("blogHeading")}</h1>
            <Image className="blog-image" src={blogImg}
              alt={t("blogHeaderImageAlt")}
               />
            <p className="contact-info blog-infos blog-info-1">{t("blogDescription1")}</p>
            <p className="contact-info blog-infos">{t("blogDescription2")}<a className="nerdy-blog-link" href="https://www.nerdybrace.com/" target="_blank" rel="noopener noreferrer">{t("blogName")}</a>{t("blogDescription3")}</p>
            <p className="contact-info blog-infos mb-4">{t("blogDescription4")}</p>
            
            <p className="blog-note"><em className="mb-4">{t("blogNote")}</em></p>
            <Image className="blog-image" src={logo}
              alt={t("blogImageAlt")}
               />
            <Button variant="primary" className="check-out fade-in-up-on-scroll ps-3 mt-4 pe-3" href="https://www.nerdybrace.com/" target="_blank" rel="noopener noreferrer"><span title={t("blogButtonTitle")} className="about-buttons-small-screen checkOutSmall">{t("blogButtonInfo")}</span></Button>
            
          </div>
          
           
          


        </Col>
      </Row>
    </Container>
  )

}


